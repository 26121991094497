body {
  margin: 0;
  padding: 0;
  background-color: #2c475c;
  font-family: 'proxima-nova', sans-serif;
}

.box-container {
  padding: 0;
  margin: 0;
  height: 90dvh;
  background-color: #144361;
  overflow: hidden;
}

.top-row-container {
  height: 100%;
}

.bottom-row-container {
  height: 100%;
}

/* Main headings (like "Team target van November") */
.heading-primary {
  text-align: center;
  font-size: clamp(1rem, 1.2vw, 6.0rem);
  font-weight: bold;
  line-height: 1;
  color: #6C6C6B;
}

.h-auto.w-100 {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  height: 100%;
}

/* Speedometer values (Bedrag/Te gaan) */
.value-display {
  text-align: center;
  font-weight: bold;
  width: 100px;
  color: #6C6C6B;
  line-height: 1.2;
  margin: 0;
}

.value-display-target {
  text-align: center;
  font-weight: bold;
  width: 100px;
  color: #6C6C6B;
  line-height: 1.2;
  margin: 0;
  font-size: 2rem !important;
}

.bodyDashboard {
  width: 100%;
  max-height: 100dvh;
  display: flex;
  flex-direction: column;
  background-color: #144361;
  position: relative;
}



.top-row-container {
  display: flex;
  flex-direction: row;
}


@media (max-width: 992px) {
  .box-container {
    height: 100% !important;
  }
}

.color-blue {
  color: #2884C5;
}


@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.myButton {
  opacity: 0;
}

.myButton:hover {
  cursor: pointer;
}

.box {
  background-color: #ffffff;
  height: 33.5dvh;
}

.midbox {
  background-color: #ffffff;
  height: 37dvh;
}

.columnleft {
  height: 72dvh;
}

.biggestbox {
  background-color: #ffffff;
  height: 86.9dvh;
}

.smallbox {
  background-color: #ffffff;
  height: 13.5dvh;
  margin-top: 16px;
}

.smallboxes {
  background-color: #ffffff;
  height: 13.5dvh;
  width: 800px;
}

.box-heading {
  font-size: clamp(1em, 1vw, 5.0rem);
  color: #6C6C6B;
}

.teamTarget {
  background-color: #2884C5;
  color: white;
  width: 200px;
  height: 35px;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 5px 5px 18px #2884C5;
}

.target-bigscreen {
  margin-top: 0% !important; 
}

.box-value {
  font-size: clamp(1rem, 1.75vw, 7rem);
}


.money-value {
  color: #2884C5;
  font-size: 25px;
  font-weight: bold;
}

.money-value-big {
  color: #2884C5;
  font-size: 1.5rem;
  font-weight: bold;
}

.value-small {
  font-size: clamp(1.25rem, 1.1vw, 5.0rem);
  font-weight: bold;
  color: #2D4C67;
}

.label-small {
  font-size: clamp(0, 8rem, 1.1vw, 5rem);
  color: #6C6C6B;
}

.dateBox>div>h3 {
  background-color: #F5F7F4;
  color: #2D4C67;
  border-radius: 5px;
  font-weight: bold;
}

.hourMinute {
  background-color: #2D4C67;
  color: white;
  font-weight: bold;
  height: 60px;
  width: 75px;
  margin: 5px;
  border-radius: 8px;
}

.bestSeller {
  font-weight: bold;
}

.sellerofthemonth {
  color: #2884C5;
  font-size: clamp(1.5rem, 1.75vw, 6rem);
}

.icon {
  color: #2D4C67;
  transform: rotate(20deg);
}

.biggestBoxChart {
  position: relative;
  bottom: 70px;
}

.Scroll {
  max-height: 100%;
  
  scrollbar-color: #2D4C67 #f1f1f1;
  scrollbar-width: thin;
}

.to-go {
  font-weight: bold;
  color: #2D4C67;
  font-size: 25px;
}

.textEndGoal {
  position: relative;
  font-size: small;
  bottom: 50px;
  font-size: clamp(1rem, 1.5vw, 2.5rem);
}

.custom-spinner img {
  animation: spin 0.7s linear infinite;
}

/* Chart Container Styles */
.month-chart-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.plot-container {
  max-height: 300px;
}

/* .xaxislayer-above .xtick text .line {

} */

/* Plot Responsive Styles */
.responsive-plot {
  width: 100% !important;
  height: 100% !important;
}

/* Core text scaling - simplified to avoid conflicts */
.js-plotly-plot .plotly .ytick text,
.js-plotly-plot .plotly .xtick text {
  font-weight: bold !important;
}

g[class*='xaxislayer-above.xtick'],
g[class^='xaxislayer-above.xtick'] text {
  color: pink;
  fill: pink;
}



/* Container queries for better responsiveness */
@container (min-width: 768px) {
  .chart-wrapper {
    padding: clamp(1rem, 2vw, 2rem);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


@media (min-width: 992px) {
  .month-chart-container {
    height: auto !important;
  }
}

@media screen and (min-width: 1900px) {
  .xaxislayer-above .xtick text {
    font-size: 10px !important;
  }
}

@media screen and (min-width: 1400px) {
  .xaxislayer-above .xtick text {
    font-size: 12px !important;
  }
}

@media screen and (min-width: 1900px) {
  .xaxislayer-above .xtick text {
    font-size: 16px !important;
    x: 10;
  }
}

@media screen and (min-width: 1440px) {
  .target-bigscreen {
    margin-top: 4%;
  }

  .dateBox {
    padding-top: 1%;
  }

  .money-value-big {
    font-size: 2rem;
  }
}

@media screen and (min-width: 2400px){
  .heading-primary {
    font-size: 3.5rem;
  }

  .teamTarget {
    width: 350px;
    height: 70px;
    box-shadow: 5px 5px 18px #2884C5;
    margin-top: 10px;
  }

  .box {
    margin-top: 1.5%;
  }
}