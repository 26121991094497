nav {
  height: 10dvh;
}

.myButton {
  opacity: 0;
}

#logoNav {
  height: 6dvh;
  width: fit-content;
}

#popoverButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  font-size: 2.5rem;
  border-color: #F5F5F4;
  border-style: solid;
  border-radius: 15px;
  background: #F5F5F4;
  color: white;
  height: 5dvh !important;
  min-width: 4vw !important;
}

.month:hover {
  cursor: pointer;
}

.MonthShow {
  border-color: #2C475C;
  border-style: solid;
  border-radius: 15px;
  font-size: 2.68dvh;
}

.MonthPicker {
  width: 350px !important;
  padding: 20px;
}

.MonthPicker button {
  font-size: 1.8rem;
  padding: 10px 20px;
  width: 70px;
}

.MonthPicker h5 {
  font-size: 2.2rem;
  margin-bottom: 15px;
}

.bodyPopover {
  width: 400px;
  padding: 25px;
  border-radius: 15px;
}

.popover {
  max-width: 500px !important;
}

h5.date {
  font-size: 2rem;
  font-weight: bold;
}

@media screen and (min-width: 3840px) {
  #popoverButton {
    height: 120px;
    width: 120px;
    font-size: 3.5rem;
  }

  .MonthShow {
    font-size: 3.5rem;
    padding: 20px 40px;
  }

  .MonthPicker {
    width: 450px !important;
    padding: 30px;
  }

  .MonthPicker button {
    font-size: 2.5rem;
    padding: 15px 25px;
    width: 90px;
  }

  .MonthPicker h5 {
    font-size: 3rem;
  }

  .bodyPopover {
    width: 500px;
    padding: 30px;
  }

  h5.date {
    font-size: 2.5rem;
  }
}